import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the consent cookie already exists
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      setIsVisible(true); // Show popup if consent not given
    }
  }, []);

  const acceptCookies = () => {
    // Set the consent cookie
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setIsVisible(false); // Hide the popup
  };

  if (!isVisible) {
    return null; // Don't render the popup if it's not visible
  }

  return (
    <div style={styles.container}>
      <p style={styles.text}>
        We use cookies to improve your experience on our site. By accepting, you consent to our use of cookies.
      </p>
      <button onClick={acceptCookies} style={styles.button}>
        Accept
      </button>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: '10px',
    left: '10px',
    right: '10px',
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
  },
  text: {
    margin: 0,
    paddingRight: '20px',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default CookieConsent;
