import React, { useRef, useState } from "react";
import "./ContactUs.css";
import Navbar from "../../Components/Navbar/Navbar";
import Menu from "../../Components/Menu/Menu";
import { Box, useToast } from "@chakra-ui/react";
import Footer from "../../Components/Footer/Footer";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const form = useRef();
  const toast = useToast();
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      toast({
        title: "Please complete the reCAPTCHA.",
        position: "top-right",
        isClosable: true,
        status: "error",
        duration: 2000,
      });
      return;
    }

    // Debugging: Log the form data
    console.log("Form data:", new FormData(form.current));

    emailjs
      .sendForm(
        'service_qakdytf', // Service ID
        'template_a3c8nig', // Template ID
        form.current, // Form reference
        'UsYqkvb4CDNhOoBbj' // Public Key
      )
      .then(
        (result) => {
          console.log("Message sent:", result.text);
          e.target.reset(); // Reset form fields on success
          setCaptchaValue(null); // Reset captcha value after successful submission
          toast({
            title: "Submitted Successfully",
            position: "top-right",
            isClosable: true,
            status: "success",
            duration: 2000,
          });
        },
        (error) => {
          console.error("Failed to send message:", error);
          toast({
            title: "Submission Failed",
            position: "top-right",
            isClosable: true,
            status: "error",
            duration: 2000,
          });
        }
      );
  };

  return (
    <div>
      <Menu />
      <Box className="bodyContainerContactUs">
        <Box className="BannerContainerContactUs">
          <Navbar />
        </Box>
        <div className="contactUsContainer">
          <h1 className="headings">Connect With Us</h1>
          <p>
            We would love to respond to your inquiries and help you succeed. Feel
            free to get in touch with us.
          </p>

          <div className="contactBox">
            <div className="contactLeft">
              <h1>Send your request</h1>
              <form ref={form} onSubmit={sendEmail}>
                <div className="inputRow">
                  <div className="inputGroup">
                    <label>Name</label>
                    <input type="text" name="first_name" placeholder="abc" required />
                  </div>
                  <div className="inputGroup">
                    <label>Phone</label>
                    <input type="text" name="user_phone" placeholder="+1 123 456 7890" required />
                  </div>
                </div>

                <div className="inputRow">
                  <div className="inputGroup">
                    <label>Email</label>
                    <input type="email" name="email" placeholder="abc@gmail.com" required />
                  </div>
                  <div className="inputGroup">
                    <label>Company</label>
                    <input type="text" name="user_company" placeholder="abc" />
                  </div>
                </div>
                <div className="inputRow">
                  <div className="inputGroup">
                    <label>Reason for Inquiry</label>
                    <input type="text" name="user_reason" placeholder="abcd" />
                  </div>
                </div>

                <label>Message</label>
                <textarea rows="5" name="message" placeholder="Your Message" required></textarea>

                {/* reCAPTCHA Component */}
                <ReCAPTCHA
                 sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // Replace with your site key
                  onChange={handleCaptcha}
                  style={{ margin: "20px 0" }}
                />

                <button type="submit">SEND</button>
              </form>
            </div>
            <div className="contactRight">
              <h1>Reach us</h1>
              <table>
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td className="contactRight_txt_color">info@radiantsage.com</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td className="contactRight_txt_color">
                      +1 855 723 7243, <br />+1 855 RAD-SAGE
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Box mt={"3rem"}>
          <Footer />
        </Box>
      </Box>
    </div>
  );
};

export default ContactUs;
