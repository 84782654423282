import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Logo from "../../Images/RSV-Logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useToast } from "@chakra-ui/react";
import {
  Box,
  Button,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import SearchInput from "../SearchComp/SearchInput";
import SearchResultsList from "../SearchComp/SearchResultsList";
import ModalComponent from "../ModalComp/ModalButton";

const Navbar = () => {
  const form = useRef();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const RFPBtnModal = useDisclosure();
  const [results, setResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCaptcha = (token) => {
    setCaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      toast({
        title: 'Please complete the CAPTCHA.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    emailjs
      .sendForm(
        'service_qakdytf', // Service ID
        'template_a3c8nig', // Template ID
        form.current, // Form reference
        'UsYqkvb4CDNhOoBbj' // Public Key
      )
      .then(
        (result) => {
          console.log('Email sent successfully!', result);
          e.target.reset(); // Reset the form after successful submission
          setCaptchaToken(null); // Reset CAPTCHA
          toast({
            title: 'Email sent successfully!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
        (error) => {
          console.error('Email sending error:', error);
          toast({
            title: 'Failed to send email.',
            description: 'Please try again later.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Box className="ManiNavPrnt">
      <Box className="nav-parent">
        {/* Hamburger */}
        <div className="hamburger">
          <div id="nav-icon" onClick={onOpen}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* Logo */}
        <Box className="logoContainer">
          <Link to={"/"}>
            <img src={Logo} alt="Radiant Sage" />
          </Link>
        </Box>

        <Box className="nav-content">
          <Box className="nav-input">
            <SearchInput setResults={setResults} />
            <SearchResultsList results={results} />
          </Box>

          <Box className="nav-ref-container">
            <Button
              size={{ base: "xs", sm: "sm" }}
              p={"0px 20px 0px 20px"}
              borderRadius={"15px"}
              colorScheme="#131049"
              bg="#131049"
              onClick={RFPBtnModal.onOpen}
            >
              RFP
            </Button>
            <Modal
              isOpen={RFPBtnModal.isOpen}
              isCentered
              onClose={RFPBtnModal.onClose}
              size={{ base: "xs", sm: "sm", md: "md", lg: "xl", xl: "xl" }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <Text fontSize={"24px"} color={"blue"}>
                    Request for Proposal
                  </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  {/* Form */}
                  <form ref={form} onSubmit={sendEmail}>
                    <FormLabel>Name</FormLabel>
                    <Input placeholder="Name" name="first_name" required />

                    <FormLabel mt={2}>Phone</FormLabel>
                    <Input
                      type="number"
                      placeholder="Phone"
                      name="user_phone"
                      required
                    />

                    <FormLabel mt={2}>Email</FormLabel>
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                    />

                    <FormLabel mt={2}>Company</FormLabel>
                    <Input placeholder="Company" name="user_company" />

                    <FormLabel mt={2}>Reason for Inquiry</FormLabel>
                    <Input
                      placeholder="Reason for Inquiry"
                      name="user_reason"
                    />

                    <FormLabel mt={2}>
                      Specific Requirements (if any):
                    </FormLabel>
                    <Textarea
                      placeholder="Specific Requirements"
                      name="message"
                      maxLength={500}
                    />

                    {/* reCAPTCHA */}
                    <ReCAPTCHA
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // Replace with your site key
                      onChange={handleCaptcha}
                    />

                    <Input
                      type="submit"
                      mt={3}
                      value="Send"
                      cursor="pointer"
                      backgroundColor="#131049"
                      color="white"
                    />
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>

          {/* Demo Button */}
          <Box className="nav-demo-container">
            <Button
              size={{ base: "xs", sm: "sm" }}
              p={"0px 15px 0px 15px"}
              borderRadius={"15px"}
              colorScheme="#131049"
              bg="#131049"
              onClick={openModal}
            >
              DEMO
            </Button>
            <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
          </Box>
        </Box>
        {/* SmallScreen drop */}
      </Box>
    </Box>
  );
};

export default Navbar;
