import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import {
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";

const ModalComponent = ({ isOpen, onClose }) => {
  const form = useRef();
  const toast = useToast();
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      toast({
        title: "Please complete the reCAPTCHA.",
        position: "top-right",
        isClosable: true,
        status: "error",
        duration: "2000",
      });
      return;
    }

    emailjs
      .sendForm(
        'service_qakdytf', // Service ID
        'template_a3c8nig', // Template ID
        form.current, // Form reference
        'UsYqkvb4CDNhOoBbj' // Public Key
      )
      .then(
        (res) => {
          console.log(res.text);
          console.log("Message sent");
          e.target.reset();
          setCaptchaValue(null); // Reset captcha value after successful submission
          toast({
            title: "Submitted Successfully",
            position: "top-right",
            isClosable: true,
            status: "success",
            duration: "2000",
          });
        },
        (error) => {
          console.log(error.text);
          toast({
            title: "Submission failed",
            position: "top-right",
            isClosable: true,
            status: "error",
            duration: "2000",
          });
        }
      );
  };

  useEffect(() => {
    // Inject CSS to hide the reCAPTCHA alert message
    const style = document.createElement('style');
    style.innerHTML = '#rc-anchor-alert { display: none !important; }';
    document.head.appendChild(style);

    // Clean up the injected style when component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      size={{ base: "xs", sm: "sm", md: "md", lg: "xl", xl: "xl" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={"24px"} color={"blue"}>
            Contact a Solutions Specialist
          </Text>
          <Text fontSize={"16px"}>
            Reach out today and an expert will be in touch to learn more about
            your specific needs.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {/* Form */}
          <form ref={form} onSubmit={sendEmail}>
            <FormLabel>Name</FormLabel>
            <Input placeholder="Name" name="user_name" />

            <FormLabel mt={2}>Phone</FormLabel>
            <Input type="number" placeholder="Phone" name="user_phone" />
            <FormLabel mt={2}>Email</FormLabel>
            <Input type="email" placeholder="Email" name="user_email" />
            <FormLabel mt={2}>Company</FormLabel>
            <Input placeholder="Company" name="user_company" />
            <FormLabel mt={2}>Reason for Inquiry</FormLabel>
            <Input placeholder="Reason for Inquiry" name="user_reason" />
            <FormLabel mt={2}>Specific Requirements (if any):</FormLabel>
            <Textarea
              placeholder="Specific Requirements"
              name="user_specific_reason"
              maxLength={500}
            />
            
            {/* reCAPTCHA Component */}
            <ReCAPTCHA
             sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // Replace with your site key
              onChange={handleCaptcha}
              style={{ margin: "20px 0" }}
            />

            <Input
              type="submit"
              mt={3}
              value={"Send"}
              cursor={"pointer"}
              backgroundColor={"#131049"}
              color={"white"}
            />
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
